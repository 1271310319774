<template>
  <div class="lecture">
    <div class="banner">
      <img src="@/assets/images/lecture/banner.png" alt="" />
    </div>
    <div class="lecture-inner">
      <div class="menu">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            :title="cat.name"
            :name="cat.name"
            v-for="cat in cateList"
            :key="cat.id"
          >
            <div class="title" slot="title">
              {{ cat.name }}
            </div>
            <div class="sub-menu">
              <div
                class="sub-menu-item"
                v-for="childCat in cat.childData"
                :key="childCat.id"
                @click="handleCateClick(childCat.id)"
              >
                {{ childCat.name }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="lecture-list">
        <div class="list-content">
          <div
            v-for="item in resultList"
            :key="item.id"
            class="list-item"
            @click="$router.push(`/web/lecture/${item.id}`)"
          >
            <div class="thumb">
              <img :src="item.image" alt="" />
              <div class="info">共一节</div>
              <div class="type">{{ TYPE[item.type] }}</div>
            </div>
            <div class="content">
              <div class="title">{{ item.title }}</div>
              <div class="meta">
                <div class="desc">
                  {{ $format(item.cdatetime, "YYYY-MM-DD") }}
                </div>
                <div class="date">{{ item.view_count }} 次</div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          class="pager"
          background
          layout="prev, pager, next"
          :page-count="maxPage"
          @prev-click="fetchList"
          @next-click="fetchList"
          @current-change="fetchList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Pagination } from "element-ui";
import otherApis from "@/apis/other";

const TYPE = {
  1: "图文",
  2: "视频"
};

export default {
  name: "Lecture",
  components: {
    // "van-list": List
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Pagination.name]: Pagination
  },
  data() {
    return {
      TYPE,
      activeNames: "",
      page: 1,
      maxPage: 1,
      finished: false,
      loading: false,
      resultList: [],
      cateList: [],
      currApi: otherApis.courseIndex,
      catId: ""
    };
  },
  created() {
    // this.fetchList();
    this.fetchCate();
    this.fetchList();
  },
  methods: {
    handleMenuChange(name) {
      console.log(name);
    },
    handleCateClick(id) {
      this.catId = id;
      this.fetchList();
    },

    async fetchCate() {
      try {
        const {
          data: { course_cat }
        } = await otherApis.courseCategory();
        this.cateList = course_cat;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchList(page) {
      this.page = page;
      try {
        const {
          data: { course_list, max_page }
        } = await this.currApi({
          page: this.page,
          cat_id: this.catId,
          size: 12
        });
        this.maxPage = max_page;
        this.resultList = course_list;
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    changeType(tab) {
      this.activeTab = tab;
      this.catId = tab.route;
      // this.currApi =
      //   type === TYPES.first ? apis.userTeams : apis.secondUserTeams;
      this.refreshList();
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    }
  }
};
</script>

<style>
.el-collapse-item__arrow {
  position: relative;
  color: #fff;
}
</style>

<style lang="scss" scope>
.lecture {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  min-height: calc(100vh - 70px);

  .banner {
    height: 300px;

    img {
      height: 233px;
      width: 963px;
    }
  }

  .lecture-inner {
    width: 1200px;
    height: 100%;
    // background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
  }
}

.menu {
  width: 200px;
  border-radius: 0px 0px 4px 4px;

  .title {
    padding: 0 20px;
    background-color: #ca0a15;
    width: 200px;
    box-sizing: border-box;
    position: absolute;
    color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px 4px 0px 0px;
  }

  .sub-menu {
    padding: 0 20px;
  }

  .sub-menu-item {
    height: 50px;
    line-height: 50px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #666666;
    border-bottom: 1px solid #b5b5b5;
    cursor: pointer;
  }
}

.lecture-list {
  height: calc(100% - 46px);
  flex: 1;
  // padding-top: 20px;

  .list-content {
    padding: 0 20px;
    display: flex;
    flex-flow: row wrap;
    overflow: auto;
    // height: calc(100% - 60px);
    // flex: none;

    .list-item {
      cursor: pointer;
      flex: none;
      height: 270px;
      width: 200px;
      background: #fff;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-bottom: 10px;
      // padding: 14px 20px;
      box-sizing: border-box;
      margin-left: 20px;

      &:nth-of-type(4n + 1) {
        margin-left: 0;
      }

      .thumb {
        height: 200px;
        width: 200px;
        overflow: hidden;
        position: relative;

        .type {
          width: 60px;
          height: 30px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          position: absolute;
          top: 10px;
          left: 10px;
          color: #fdae04;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
        }

        .info {
          height: 30px;
          width: 200px;
          color: #fff;
          font-size: 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
          line-height: 30px;
          text-align: center;
        }

        img {
          height: 200px;
          width: 200px;
          object-fit: cover;
        }
      }

      .content {
        padding: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .title {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }

        .meta {
          font-size: 12px;
          color: #666666;
          display: flex;
          justify-content: space-between;
          margin-top: 14px;

          .desc {
            max-width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      // .desc {
      //   margin-top: 12px;
      //   font-size: 14px;
      //   color: #666666;
      //   white-space: nowrap;
      //   width: 100%;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      // .date {
      //   margin-top: 19px;
      //   font-size: 12px;
      //   color: #b5b5b5;
      // }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .pager {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
